import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(() => ({
  root: {
    '& > .Mui-disabled': {
      backgroundColor: `#F2F2F2`,
    },
  },
}))

function SelectInput({
  label = ``,
  options = [],
  onChange = () => {},
  selectLabel = `Select an option`,
  errorMsg = null,
  fullWidth = true,
  ...props
}) {
  const classes = useStyles()
  return (
    <>
      <TextField
        {...props}
        classes={{ root: classes.root }}
        onChange={({ target }) => onChange(target.value)}
        fullWidth={fullWidth}
        label={label}
        select
      >
        <MenuItem value="select" disabled>
          {selectLabel}
        </MenuItem>
        {options.map(({ value, description }, index) => (
          <MenuItem key={index} value={value}>
            {description}
          </MenuItem>
        ))}
      </TextField>
      {errorMsg && (
        <FormControl error>
          <FormHelperText id="component-error-text">{errorMsg}</FormHelperText>
        </FormControl>
      )}
    </>
  )
}

export default SelectInput
