import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(() => ({
  root: {
    '& > .Mui-disabled': {
      backgroundColor: `#F2F2F2`,
    },
  },
}))

function TextFieldInput({ type = `text`, label = ``, InputProps, ...props }) {
  const classes = useStyles()

  return (
    <>
      <TextField {...props} classes={{ root: classes.root }} type={type} fullWidth label={label} />
    </>
  )
}

export default TextFieldInput
