/** @format */

import React from 'react'
// import { CartProvider } from "./../context/CartContext";

const FullPageLayout = ({ children, ...rest }) => {
  return <>{children}</>
}

export default FullPageLayout
