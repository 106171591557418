import React, { useState, useContext } from 'react'

const PrivateContext = React.createContext()

function PrivateProvider({ children }) {
  const [state, setState] = useState({ password: null })

  function setPassword(password) {
    const updateState = {
      password: password,
    }
    setState(updateState)
  }

  const { password } = state
  return (
    <PrivateContext.Provider
      value={{
        password,
        setPassword,
      }}
    >
      {children}
    </PrivateContext.Provider>
  )
}

function usePrivate() {
  const context = useContext(PrivateContext)
  return context
}

export { PrivateProvider, usePrivate }
