import React, { Suspense } from 'react'
import { BrowserRouter as ReactRouter, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Spinner from '../components/custom/spinnerLoader'
import SpinnerLogo from '../components/custom/spinnerLogoLoader'
import App from '../App'
import FullPageLayout from '../layouts/routes/fullPageRoutes'
import FullPageSessionLayout from '../layouts/routes/fullPageSessionRoutes'
import FullPagePrivateLayout from '../layouts/routes/fullPagePrivateRoutes'
import config from '../config/secrets'

const Login = React.lazy(() => import('../containers/Login'))
const PasswordReset = React.lazy(() => import('../containers/PasswordReset'))
const Home = React.lazy(() => import('../containers/Home'))
const Register = React.lazy(() => import('../containers/Register'))
const Sale = React.lazy(() => import('../containers/Sale'))
const BulkLoadGeneral = React.lazy(() => import('../containers/BulkLoadGeneral'))
const SearchProduct = React.lazy(() => import('../containers/SearchProduct'))
const SearchManufacturer = React.lazy(() => import('../containers/SearchManufacturer'))
const ManufacturerDetails = React.lazy(() => import('../containers/ManufacturerDetails'))
const SearchManufacturerDetails = React.lazy(() => import('../containers/SearchManufacturerDetails'))
const ShoppingCartSummary = React.lazy(() => import('../containers/ShoppingCart'))
const ShoppingSummary = React.lazy(() => import('../containers/ShoppingSummary'))
const ProductDetails = React.lazy(() => import('../containers/ProductDetails'))
const PurchaseInformation = React.lazy(() => import('../containers/PurchaseInformation'))
const PayDetails = React.lazy(() => import('../containers/PayDetail'))
const MakePayment = React.lazy(() => import('../containers/MakePayment'))
const PayNow = React.lazy(() => import('../containers/PayNow'))
const Voucher = React.lazy(() => import('../containers/Voucher'))
const Orders = React.lazy(() => import('../containers/Orders'))
const OrderDetail = React.lazy(() => import('../containers/OrderDetail'))
const LineFinder = React.lazy(() => import('../containers/LineFinder'))
const Inbox = React.lazy(() => import('../containers/Inbox'))
const EditProduct = React.lazy(() => import('../containers/EditProduct'))
const ActivateProducts = React.lazy(() => import('../containers/ActivateProducts'))
const Edit = React.lazy(() => import('../containers/Edit'))
const OrderView = React.lazy(() => import('../containers/OrderView'))
const BuyerOrderView = React.lazy(() => import('../containers/BuyerOrderView'))
const MostRecentPost = React.lazy(() => import('../containers/MostRecentPosts'))
const Units = React.lazy(() => import('../containers/Units'))
const ReadyToSend = React.lazy(() => import('../containers/ReadyToSend'))
const Static = React.lazy(() => import('../containers/Static'))
const Inventory3PL = React.lazy(() => import('../containers/Inventory3PL'))
const MyAccount = React.lazy(() => import('../containers/MyAccount'))
const MyUsers = React.lazy(() => import('../containers/MyUsers'))
const SimplifiedRegister = React.lazy(() => import('../containers/SimplifiedRegister'))
const OrderShipping = React.lazy(() => import('../containers/OrderShipping'))
const AccountBank = React.lazy(() => import('../containers/AccountBank'))
const AccountBankForm = React.lazy(() => import('../containers/AccountBankForm'))
const PollAccount = React.lazy(() => import('../containers/PollAccount'))
const PriceList = React.lazy(() => import('../containers/PriceList'))
const MyQuotes = React.lazy(() => import('../containers/MyQuotes'))
const CreateQuote = React.lazy(() => import('../containers/CreateQuote'))
const Default = React.lazy(() => import('../containers/DefaultComponent'))
const SendQuote = React.lazy(() => import('../containers/SendQuote'))
const ViewQuote = React.lazy(() => import('../containers/MyCellar/Quotes/view'))
const Catalogs = React.lazy(() => import('../containers/Catalogs'))
const Distributor = React.lazy(() => import('../containers/Distributor'))
const DirectoFabrica = React.lazy(() => import('../containers/DirectoFabrica'))
const AliadosRep = React.lazy(() => import('../containers/AliadosRep'))
const AltaCalidad = React.lazy(() => import('../containers/AltaCalidad'))
const Terms = React.lazy(() => import('../containers/Terms'))
const Resolucion = React.lazy(() => import('../containers/Resolucion'))
const Contactenos = React.lazy(() => import ('../containers/Contactenos'))
const Preguntas = React.lazy(() => import('../containers/Preguntas'))

const Router = ({ props }) => {
  const { ready } = useTranslation('routes', { useSuspense: false })

  return (
    <ReactRouter>
      {ready && (
        <App>
          {config.inConstruction ? (
            <Switch>
              <FullPageLayout
                exact
                path="*"
                render={(matchprops) => (
                  <Suspense fallback={<SpinnerLogo />}>
                    <Static {...matchprops} />
                  </Suspense>
                )}
              />
            </Switch>
          ) : (
            <>
              <Switch>
                <FullPageLayout
                  exact
                  path="/under-construction"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Static {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageSessionLayout
                  exact
                  path="/(login|iniciar-sesion)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Login {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPageLayout
                  exact
                  path="/(catalogs|catalogos)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Catalogs {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(distributor|distribuidor)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Distributor {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(direct|directo)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <DirectoFabrica {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(partnership|aliados)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <AliadosRep {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(hightquality|altacalidad)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <AltaCalidad {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(terms|terminos)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Terms {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(resolution|resolucion)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Resolucion {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(contactus|contactenos)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Contactenos {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(faq|preguntas)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Preguntas {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPageLayout
                  exact
                  path="/(register|registro)/:token"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Register {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(simplified-register|registro-simplificado)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <SimplifiedRegister {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(password-reset|restablecer-contrasena)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <PasswordReset {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(password-reset|restablecer-contrasena)/:token"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <PasswordReset {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(search-product|buscar-producto)/:firstSearch/:code?"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <SearchProduct {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(search-manufacturer|buscar-fabricante)/:code?"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <SearchManufacturer {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(manufacturer-details|detalles-fabricante)/:manufacturerId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <ManufacturerDetails {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/(search-manufacturer-details|buscar-detalles-fabricante)/:line/:manufacturerId/:keyword?"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <SearchManufacturerDetails {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(sale|vender)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Sale {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  path="/(bulk-load|carga-masiva)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <BulkLoadGeneral {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/summary|carrito-de-compras/resumen)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <ShoppingCartSummary {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/pay-detail|shopping-cart/detalles-de-pago)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <PayDetails {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/pay|carrito-de-compras/pagar)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <ShoppingSummary {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPageLayout
                  exact
                  path="/(products/details|productos/detalles)/:productId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <ProductDetails {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/purchase-information|carrito-de-compras/informacion-de-compra)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <PurchaseInformation {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/make-payment|carrito-de-compras/realizar-pago)/:orderId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <MakePayment {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/pay-now|carrito-de-compras/pagar-ahora)/:orderId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <PayNow {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/voucher|carrito-de-compras/comprobante)/:orderId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Voucher {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(orders|pedidos)/:tab"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Orders {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/orders/order-detail|carrito-de-compras/detalle-de-pedido)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <OrderDetail {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPageLayout
                  exact
                  path="/(line-finder|buscador-por-linea)/:categoryCode/:minMax?"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <LineFinder {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(edit-product|editar-producto)/:productId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <EditProduct {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(activate-deactivate-products|activar-desactivar-productos)/:page?"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <ActivateProducts {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(edit-product|editar-producto)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <EditProduct {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(inbox|mensajes)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Inbox {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(edit|editar)"
                  render={(matchprops) => (
                    <Suspense fallback={<Spinner />}>
                      <Edit {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(sale/order|vender/pedido)/:orderId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <OrderView {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(most-recent-post|publicaciones-mas-recientes)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <MostRecentPost {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPagePrivateLayout
                  exact
                  path="/(shopping-cart/order|carrito-de-compras/pedido)/:orderId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <BuyerOrderView {...matchprops} />
                    </Suspense>
                  )}
                />

                <FullPageLayout
                  exact
                  path="/(units|unidades)/:categoryCode"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Units {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(ready-to-send|listo-para-enviar)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <ReadyToSend {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(inventory3PL|inventario3PL)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Inventory3PL {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(my-account|mi-cuenta)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <MyAccount {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(users|usuarios)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <MyUsers {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(order/shipping|pedido/envio)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <OrderShipping {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(account-bankaccount|micuenta-cuentabancaria)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <AccountBank {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(account-bankaccount-form|micuenta-cuentabancaria-form)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <AccountBankForm {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(my-quotes|mis-cotizaciones)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <MyQuotes {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(my-quotes/create|mis-cotizaciones/crear)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <CreateQuote {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(my-quotes/edit|mis-cotizaciones/editar)/:quoteId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <CreateQuote {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(my-quotes/send|mis-cotizaciones/enviar)/:quoteId"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <SendQuote {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/my-cellar/quotes/view/:id"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <ViewQuote {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(account-poll|micuenta-encuesta)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <PollAccount {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(account-poll|micuenta-encuesta)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <PollAccount {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPagePrivateLayout
                  exact
                  path="/(account-price-list|micuenta-lista-precios)"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <PriceList {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  exact
                  path="/"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Home {...matchprops} />
                    </Suspense>
                  )}
                />
                <FullPageLayout
                  path="*"
                  render={(matchprops) => (
                    <Suspense fallback={<SpinnerLogo />}>
                      <Default {...matchprops} />
                    </Suspense>
                  )}
                />
              </Switch>
            </>
          )}
        </App>
      )}
    </ReactRouter>
  )
}

export default Router
