export class ResponseUtils {
  responseError(code) {
    switch (code) {
      case 40:
        return { name: 'token', error: { lang: 'unauthorized', text: 'Unauthorized access' } }
      case 102:
        return { name: 'server', error: { lang: 'connection_refused', text: 'Error connection refused' } }
      case 400:
        return { name: 'token', error: { lang: 'unauthorized', text: 'Unauthorized access' } }
      case 403:
        return {
          name: 'notpermissions',
          error: { lang: 'not_permissions', text: '"You do not have permissions to perform this operation"' },
        }
      case 422:
        return { name: 'passwordMessage', error: { lang: 'invalid_request_data', text: 'Invalid request data' } }
      case 50:
        return { name: 'dataNotFound', error: { lang: 'data_not_found', text: 'Data not found' } }
      default:
        return { name: 'server', error: { lang: 'server_error', text: 'Server error' } }
    }
  }
}
