import React from 'react'
import PropTypes from 'prop-types'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SnackbarComponent = ({
  openSnackbar,
  typeSnackbar,
  messageSnackbar,
  handleCloseSnackbar,
  timeSnackbar = 6000,
  vertical = 'top',
  horizontal = 'right',
}) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnackbar}
        autoHideDuration={timeSnackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={typeSnackbar}>
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </>
  )
}

SnackbarComponent.propTypes = {
  openSnackbar: PropTypes.bool.isRequired,
  typeSnackbar: PropTypes.string.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  messageSnackbar: PropTypes.string,
  timeSnackbar: PropTypes.number,
}

export default SnackbarComponent
