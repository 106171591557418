import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'

import BrowserLanguage from '../utils/browserLanguage'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      debug: process.env.NODE_ENV !== 'production',
      lng: BrowserLanguage.getDefaultLanguage(),
      fallbackLng: 'en',
      whitelist: ['en', 'es'],
      interpolation: {
        escapeValue: false,
      },
      react: {
        bindI18n: 'languageChanged',
        transSupportBasicHtmlNodes: false,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
      },
      crossDomain: true,
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
    },
    function (err, t) {
      if (err) return console.error('something went wrong loading', err)
    },
  )
export default i18n
