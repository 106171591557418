import React from 'react'
import { withRouter } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import './App.css'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import themeMyRobock from './config/themeMyRobock'

import {
  AuthProvider,
  BreadcrumbProvider,
  FeedbackProvider,
  PrivateProvider,
  SocketProvider,
  UIProvider,
} from './context'
import { SnackbarProvider } from 'notistack'
import HttpClientManagement from './API/httpClientManagement'

function App(props) {
  return (
    <div className="App">
      <HttpClientManagement>
        <FeedbackProvider>
          <SocketProvider>
            <PrivateProvider>
              <AuthProvider>
                <UIProvider>
                  <BreadcrumbProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <MuiThemeProvider theme={themeMyRobock}>
                        <SnackbarProvider
                          maxSnack={3}
                          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                          autoHideDuration={6000}
                        >
                          {props.children}
                        </SnackbarProvider>
                      </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                  </BreadcrumbProvider>
                </UIProvider>
              </AuthProvider>
            </PrivateProvider>
          </SocketProvider>
        </FeedbackProvider>
      </HttpClientManagement>
    </div>
  )
}

export default withRouter(App)
