import { CONSTANT } from '../config/CONSTANT'

const BrowserLanguage = {
  getBrowserLanguage() {
    return navigator.language || navigator.userLanguage
  },

  getPrevLanguage() {
    return localStorage ? localStorage.getItem(CONSTANT.LOCAL_STORAGE_LANG_KEY) : null
  },

  setLanguage(lang) {
    if (localStorage) {
      localStorage.setItem(CONSTANT.LOCAL_STORAGE_LANG_KEY, lang)
      return true
    }
    return false
  },

  getDefaultLanguage() {
    const langSet = this.getPrevLanguage()
    if (langSet) {
      return langSet
    }
    const browserLang = this.getBrowserLanguage()

    if (browserLang) {
      var language = ''
      for (let lang of CONSTANT.LANGUAGES) {
        if (browserLang.includes(lang.key)) {
          language = lang.key
          break
        }
      }
      return language || 'en'
    }
  },
}

export default BrowserLanguage
