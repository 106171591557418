import axios from 'axios'
import config from '../config/secrets'
import { CONSTANT } from '../config/CONSTANT'

const API = {}
const baseURLSocket = `${config.SOCKET_URL}${config.APP_URI}${config.APP_VERSION}`
const baseURLClient = `${config.APP_URL}${config.APP_URI}${config.APP_VERSION}`
const baseURLProd = `${config.APP_URL_PROD}${config.APP_URI}${config.APP_VERSION}`

const language = config.headers[`Accept-Language`]

function isDownloadUrl(url) {
  if (url.split(`/`).includes(`export`)) return true
}

const requestHandler = (config) => {
  const token = `Bearer ${localStorage.getItem(`token`)}`
  if (token) {
    config.headers.Authorization = token
  }
  // TODO: Change language dinamic
  config.headers[`Accept-Language`] = language

  return config
}

const responseHandler = (response) => {
  if (
    response.status === CONSTANT.ERRORS_SERVICES.accessDeniedCode &&
    response.data === CONSTANT.ERRORS_SERVICES.accessDenied
  ) {
    if (localStorage.getItem(CONSTANT.TOKEN_KEY)) window.location.replace('/')
    localStorage.clear(CONSTANT.PROFILE_KEY)
    localStorage.clear(CONSTANT.USER_KEY)
    localStorage.clear(CONSTANT.TOKEN_KEY)
  }

  const {
    data: dataBase,
    config: { url },
  } = response
  if (isDownloadUrl(url)) {
    return dataBase
  }
  const { data, error, success, code, message } = dataBase
  if (success) {
    return data
  } else if (message === 'Unauthorized access' && code === 401) {
    return dataBase
  }
  return Promise.reject({ error, code, message })
}

const axiosInstanceProd = axios.create({
  baseURL: baseURLProd,
  validateStatus() {
    return true
  },
})

const axiosInstanceSocket = axios.create({
  baseURL: baseURLSocket,
  validateStatus() {
    return true
  },
})
const axiosInstanceClient = axios.create({
  baseURL: baseURLClient,
  validateStatus() {
    return true
  },
})

axiosInstanceSocket.interceptors.request.use(requestHandler, (error) => Promise.reject(error))
axiosInstanceSocket.interceptors.response.use(responseHandler, (error) => Promise.reject(error))

axiosInstanceClient.interceptors.request.use(requestHandler, (error) => Promise.reject(error))
axiosInstanceClient.interceptors.response.use(responseHandler, (error) => Promise.reject(error))

axiosInstanceProd.interceptors.request.use(requestHandler, (error) => Promise.reject(error))
axiosInstanceProd.interceptors.response.use(responseHandler, (error) => Promise.reject(error))

// SOCKET
API.uploadFile = ({ ...payload }) => axiosInstanceSocket.post(`/file`, payload)
API.downloadFile = ({ fileId, fileName }) => axiosInstanceSocket.get(`/file/find?fileId=${fileId}&name=${fileName}`)
API.getFileById = (fileId) => axiosInstanceSocket.get(`/file/find?fileId=${fileId}`)

// CLIENT
API.getProfile = () => axiosInstanceClient.get(`/account/profile`)
API.setProfile = ({ ...payload }) => axiosInstanceClient.put(`/account/profile`, payload)
API.setEnterpriseInfo = ({ ...payload }) => axiosInstanceClient.put(`/account/enterprise`, payload)
API.getCountries = () => axiosInstanceClient.get(`/master/countries`)
API.getStates = (countryId) => axiosInstanceClient.get(`/master/states/${countryId}`)
API.getCountriesNew = () => axiosInstanceClient.get(`/master/api-countries`)
API.getStatesNew = (country) => axiosInstanceClient.get(`/master/api-states/${country}`)
API.getRoles = () => axiosInstanceClient.get(`/master/roles`)
API.signUpRequest = (payload) => axiosInstanceClient.post(`/user/register`, payload)

// PROD
API.updateUnitsManufacturer = (body) => axiosInstanceProd.put('/orders/manufacturer/product', body)
API.deleteProductManufacturer = (info) => axiosInstanceProd.delete('/orders/manufacturer/product', { data: info })
API.updatePayments = (body, orderId) => axiosInstanceProd.put(`/orders/${orderId}/payment-installments`, body)
API.getMeasurementUnits = () => axiosInstanceProd.get(`/measurement-units`)
API.addProductUnpublished = (body) => axiosInstanceProd.put('/shoppingcart/add', body)
API.deleteProductUnpublished = (data) => axiosInstanceProd.delete('/shoppingcart/destroy/draft/product', { data: data })
API.addProductUnpublishedByManufacturer = (body) =>
  axiosInstanceProd.put('/orders/manufacturer/product/draft/add', body)
API.updateProductUnpublishedByManufacturer = (body) => axiosInstanceProd.put('/orders/manufacturer/product/draft', body)
API.deleteProductUnpublishedByManufacturer = (body) =>
  axiosInstanceProd.delete('/orders/manufacturer/product/draft', { data: body })
API.getOrderShipping = (id) => axiosInstanceProd.get(`/orders/manufacturer/order/${id}`)
API.confirmShipping = (id, body) => axiosInstanceProd.put(`/orders/${id}/manufacturer/dispatch`, body)
API.saveCommentsShipping = (body) => axiosInstanceProd.post(`/orders/comments`, body)
API.getOrderBuyer = (id) => axiosInstanceProd.get(`/orders/${id}`)
API.confirmSecondPayment = (body) => axiosInstanceProd.post(`/transactions/verify-second-payment`, body)
API.confirmReceptionFinal = (id) => axiosInstanceProd.put(`/orders/confirm/reception/${id}`)
API.confirmQuantity = (body) => axiosInstanceProd.put(`/orders/manufacturer/products/dispatch/quantity`, body)
API.manufacturerProfile = (manufacturerId) => axiosInstanceProd.get(`/users/${manufacturerId}`)

export default API
