/** @format */

import axios from 'axios'

import config from '../config/secrets'

async function getCommercialTerms() {
  try {
    const token = localStorage.getItem('token')
    let response = await axios.get(config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + '/commercial-terms', {
      headers: config.headersAuth(token),
    })
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      return {
        success: false,
        code: 102,
      }
    } else {
      return {
        success: false,
        code: 10,
      }
    }
  }
}

export { getCommercialTerms }
