/** @format */

import axios from 'axios'

import config from '../config/secrets'

async function getChatHeaders() {
  try {
    const token = localStorage.getItem('token')
    let response = await axios.get(config.SOCKET_URL + config.APP_URI + config.APP_VERSION + '/messenger/chats', {
      headers: config.headersAuth(token),
    })

    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

async function findChat(keyword) {
  try {
    const token = localStorage.getItem('token')
    let response = await axios.get(
      config.SOCKET_URL + config.APP_URI + config.APP_VERSION + `/messenger/chats/find/${keyword}`,
      { headers: config.headersAuth(token) },
    )

    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

async function getChatBody(headerId, page = 1, limit = 30, orderBy = 'desc') {
  try {
    const token = localStorage.getItem('token')
    let response = await axios.get(
      config.SOCKET_URL +
        config.APP_URI +
        config.APP_VERSION +
        `/messenger/messages?headerId=${headerId}&page=${page}&limit=${limit}&orderBy=${orderBy}`,
      { headers: config.headersAuth(token) },
    )

    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

export { getChatHeaders, getChatBody, findChat }
