import React, { useState } from 'react'

import { useLocation } from 'react-router-dom'

import config from '../config/secrets'
import { CONSTANT } from '../config/CONSTANT'
import { showShoppingCart } from '../API/shoppingCart'

let ShoppingCartContext = React.createContext()
let { Provider, Consumer } = ShoppingCartContext

function ShoppingCartProvider({ children }) {
  const [shoppingCart, setShoppingCart] = React.useState([])

  const [qtyShoppingCart, setQtyShoppingCart] = React.useState(0)
  let location = useLocation()

  const getShoppingCart = React.useCallback(async () => {
    const response = await showShoppingCart()
    if (response.success && response.data) {
      setShoppingCart(response.data.docs[0])
    }
  })

  React.useEffect(() => {
    getShoppingCart()
  }, [shoppingCart])

  return (
    <Provider
      value={{
        shoppingCart,
        qtyShoppingCart,
      }}
    >
      {children}
    </Provider>
  )
}

export { ShoppingCartProvider, Consumer as ShoppingCartConsumer, ShoppingCartContext }
