import Axios from 'axios'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const httpProducts = Axios.create({
  baseURL: process.env.REACT_APP_APP_URL_PROD + process.env.REACT_APP_URI + process.env.REACT_APP_VERSION,
  timeout: process.env.REACT_APP_TIMEOUT,
})

export const httpTemplate = Axios.create({
  baseURL: process.env.REACT_APP_TEMPLATE_URL,
  timeout: process.env.REACT_APP_TIMEOUT,
})

export const httpClients = Axios.create({
  baseURL: process.env.REACT_APP_APP_URL + process.env.REACT_APP_URI + process.env.REACT_APP_VERSION,
  timeout: process.env.REACT_APP_TIMEOUT,
})

export const httpSockets = Axios.create({
  baseURL: process.env.REACT_APP_SOCKET_URL + process.env.REACT_APP_URI + process.env.REACT_APP_VERSION,
  timeout: process.env.REACT_APP_TIMEOUT,
})

function httpResponseTemplateHandling(res) {
  if ((res.status === 201 && res.data) || (res.status === 200 && res.data)) {
    return res.data
  }
}

function HttpClientManagement(props) {
  const { t } = useTranslation('http_errors', { useSuspense: false })

  const setHeaders = (httpClientAxios) => {
    const token = localStorage.getItem('token')
    httpClientAxios.headers['Accept-Language'] = localStorage.getItem('MR_LANG_KEY') || 'en'
    httpClientAxios.headers['Accept'] = 'application/json'
    httpClientAxios.headers['Content-type'] = 'application/json'
    if (token) httpClientAxios.headers['Authorization'] = `Bearer ${token}`
    return httpClientAxios
  }

  const printToConsoleError = (message, error) => {
    console.error(`error HttpClientManagement message: ${message}`)
    console.error(
      `error HttpClientManagement url: ${error.config.method?.toUpperCase()} ${error.config.baseURL}${
        error.config.url
      }`,
    )
    console.error(`error HttpClientManagement detail: ${JSON.stringify(error)}`)
    if (error.response && error.response.data) {
      const responseData = error.response.data
      console.error(
        `error HttpClientManagement code: ${responseData.code} | error: ${responseData.error} | message: ${responseData.message}`,
      )
    }
  }

  const httpResponseHandling = (response) => {
    if (!response || !response.data || !response.data.data || response.data.data.length <= 0) return null

    if (!response.data.success) {
      console.error(`success:${response.data.success}, code:${response.data.code}, message:${response.data.message}`)
      throw response.data.message
    }

    return response.data.data
  }

  const httpErrorHandling = (error) => {
    let message
    const code = error?.response?.data?.code
    message = code && t(code) != code ? t(code) : null
    console.log(error.response)
    if (error?.response?.data?.message === 'HAVE_PROCESS_ACTIVE' && error.response.status === 400) {
      message = t('http_errors:process_active', 'You already have an active bulk upload process')
      printToConsoleError(message, error)
      throw message
    }

    if (message && code) {
      message = t(code)
      printToConsoleError(message, error)
      throw message
    }

    if (error.message === 'Network Error') {
      message = t('http_errors:network_error')
      printToConsoleError(message, error)
      throw message
    }

    if (error.code === 'ECONNABORTED') {
      message = t('http_errors:not_found')
      printToConsoleError(message, error)
      throw message
    }

    if (error.response && error.response.status === 400) {
      message = `${t('http_errors:bad_request')} ${error.response.data.message}`
      printToConsoleError(message, error)
      throw message
    }

    if (error.response && error.response.status === 401) {
      message = t('http_errors:unauthorized')
      printToConsoleError(message, error)
      throw message
    }

    if (error.response && error.response.status === 404) {
      // VALIDATE SERVER BULKLOAD
      if (error.response.config.baseURL === process.env.REACT_APP_TEMPLATE_URL) {
        message = error.response.data.message
        printToConsoleError(message, error)
        throw message
      }
      message = t('http_errors:not_found')

      printToConsoleError(message, error)
      throw message
    }

    if (error.response && error.response.status === 422) {
      message = t('http_errors:unprocessable_entity')
      printToConsoleError(message, error)
      throw message
    }

    throw error
  }

  useEffect(() => {
    httpProducts.interceptors.request.use((config) => setHeaders(config))
    httpClients.interceptors.request.use((config) => setHeaders(config))
    httpSockets.interceptors.request.use((config) => setHeaders(config))
    httpTemplate.interceptors.request.use((config) => setHeaders(config))

    httpClients.interceptors.response.use(
      (res) => httpResponseHandling(res),
      (error) => httpErrorHandling(error),
    )
    httpProducts.interceptors.response.use(
      (res) => httpResponseHandling(res),
      (error) => httpErrorHandling(error),
    )
    httpTemplate.interceptors.response.use(
      (res) => httpResponseTemplateHandling(res),
      (error) => httpErrorHandling(error),
    )
    httpSockets.interceptors.response.use(
      (res) => httpResponseHandling(res),
      (error) => httpErrorHandling(error),
    )
  }, [])

  return props.children
}

export default HttpClientManagement
