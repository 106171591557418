import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SnackBar } from '../components/custom'

const FeedbackContext = React.createContext()
const initialState = { messageSnackbar: ``, openSnackbar: false, typeSnackbar: null }

function FeedbackProvider({ children }) {
  const { t, ready } = useTranslation('error_messages', { useSuspense: false })
  const [feedback, setFeedback] = useState(initialState)
  const errorDefault = ready ? t('default') : 'An error has occurred, please try again later'
  const handleSetFeedback = ({ open, message, type }) => {
    setFeedback({ messageSnackbar: message, openSnackbar: open, typeSnackbar: type })
  }
  return (
    <FeedbackContext.Provider value={handleSetFeedback}>
      <>
        {children}
        <SnackBar
          {...feedback}
          setOpen={(open) => handleSetFeedback({ open })}
          handleCloseSnackbar={() => handleSetFeedback({ open: false })}
        />
      </>
    </FeedbackContext.Provider>
  )
}

function useFeedback() {
  const context = useContext(FeedbackContext)
  if (!context) {
    throw new Error(`useFeedback must be used within a FeedbackProvider`)
  }
  return context
}

export { FeedbackProvider, useFeedback }
