import { createContext, useContext, useEffect, useState } from 'react'

const UIContext = createContext({})

const UIProvider = ({ children }) => {
  const [state, setState] = useState({
    refreshSearch: false,
  })

  const { refreshSearch } = state

  useEffect(() => {
    if (refreshSearch) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, refreshSearch: false }))
      }, 1000)
    }
  }, [refreshSearch])

  const onRefreshSearch = () => {
    setState((prevState) => ({ ...prevState, refreshSearch: true }))
  }

  return <UIContext.Provider value={{ refreshSearch, onRefreshSearch }}>{children}</UIContext.Provider>
}

function useUI() {
  const context = useContext(UIContext)
  if (!context) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export { UIProvider, useUI }
