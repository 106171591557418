import BrowserLanguage from "../utils/browserLanguage";
  

let language = BrowserLanguage.getDefaultLanguage();

export default {
    APP_URL: process.env.REACT_APP_APP_URL_USR,
    APP_URL_PROD: process.env.REACT_APP_APP_URL_PROD,
    APP_URL_SEARCH: process.env.REACT_APP_APP_URL_SEARCH,
    APP_URI: "/api",
    APP_VERSION: "/v1.0.0",
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
    SOCKET_NOTIFICATIONS_NAMESPACE:"/NOTIFICATION_NAMESPACE",
    SOCKET_MESSENGER_NAMESPACE: "/MESSENGER_NAMESPACE",
    reCAPTCHA: {
        SiteKey: process.env.REACT_APP_reCAPTCHA_SiteKey,
        SecretKey: process.env.REACT_APP_reCAPTCHA_SecretKey,
    },
    headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Accept-Language": language
    },
    headersAuth: function(token) {
        return {
            "Accept": "application/json",
            "Content-type": "application/json",
            "Accept-Language": language,
            "Authorization": `Bearer ${token}`
        }
    }
}
