import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  content_form: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '23px',
  },
  style_input: {
    width: '48%',
    color: '#1F3B53',
  },
  container_upload_file: {
    width: '100%',
    height: '155px',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  content_upload_file: {
    border: 'dashed 1px #E6E6E6',
    borderRadius: '4px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  button_upload: {
    width: 'fit-content',
    height: '30px',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px #B9CAE371',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    margin: '10px 0 0 15px',
    border: 'none !important',
    padding: '0px 12px',
    cursor: 'pointer',
  },
  text_button_upload: {
    fontSize: '12px',
    color: '#000',
    marginRight: '5px',
    fontWeight: '400',
    cursor: 'pointer',
    '& > img': {
      marginLeft: theme.spacing(1),
    },
  },
  circle_button: {
    width: '17px',
    height: '17px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid .5px #F5F5F5',
    boxShadow: '0px 1px 3px #00000029',
  },
  text_info: {
    fontSize: 12,
    color: '#A2A2A2',
    fontWeight: 300,
    marginTop: '8px',
    fontFamily: 'roboto',
  },
  d_none: {
    display: 'none',
  },
  add: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    fontWeight: '400',
    fontFamily: 'roboto',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  row_file: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100% - 15px)',
  },
  content_file: {
    width: 'calc(20% - 60px)',
    padding: '35px 20px 20px',
    height: '120px',
    margin: '0 20px 25px 0',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px #B9CAE371',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  btn_delete_file: {
    width: '18px',
    height: '18px',
    minWidth: '18px',
    minHeight: '18px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',
    backgroundColor: 'transparent',
    borderRadius: '20px',
    border: 'solid 1px black',
    color: 'black',
    position: 'absolute',
    top: '10px',
    right: '10px',
    outline: 'none',
    '&:focus': {
      border: 'solid 1px black',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 12,
    },
  },
  content_image: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '4px',
    backgroundSize: 'cover',
  },
  name_file: {
    color: '#000',
    margin: '0 auto',
    marginTop: '7px',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    boxSizing: 'border-box',
  },
  name_file_centered: {
    justifyContent: 'center',
  },
  name_file_description: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
  },
}))

export default useStyles
