import React from 'react'
import socketIOClient from 'socket.io-client'

import config from '../config/secrets'
import { CONSTANT } from '../config/CONSTANT'
import { getChatHeaders, findChat } from '../API/chatMessenger'

let SocketContext = React.createContext()
let { Provider, Consumer } = SocketContext

function SocketProvider({ children }) {
  const [qtyShoppingCart, setQtyShoppingCart] = React.useState(0)
  const [qtyOrders, setQtyOrders] = React.useState(0)
  const [qtyMessage, setQtyMessage] = React.useState(0)
  const [headerMessages, setHeaderMessages] = React.useState([])
  const [bodyMessages, setBodyMessages] = React.useState([])
  const [receiveMessage, setReceiveMessage] = React.useState(false)
  const [receiveHeaderMessage, setReceiveHeaderMessage] = React.useState(false)
  const [receiveNewMessage, setReceiveNewMessage] = React.useState(false)
  const [activeChat, setActiveChat] = React.useState(false)
  const [socketNotificationsConnection, setSocketNotificationsConnection] = React.useState(false)
  const [socketMessengerConnection, setSocketMessengerConnection] = React.useState(false)
  const token = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))

  const handleDeleteShoppingCart = () => {
    setQtyShoppingCart(0)
  }

  const disconnect = () => {
    setQtyShoppingCart(0)
    setQtyOrders(0)
    setQtyMessage(0)
    if (socketNotificationsConnection) {
      socketNotificationsConnection.close()
    }
  }

  const updateHeaderMessageFromSocket = (header) => {
    console.log('Received New Header ' + JSON.stringify(header))
    headerMessages.forEach((h) => {
      if (h._id === header._id) {
        h.unreadMessages = header.unreadMessages
        h.read = header.read
      }
    })
    setHeaderMessages(headerMessages)
  }
  const processMessageFromSocket = (message) => {
    if (message) {
      console.log('Received New Message ' + JSON.stringify(message))
      const newHeaders = headerMessages.filter((h) => h._id !== message.header._id)
      newHeaders.unshift(message.header)
      setHeaderMessages(newHeaders)
      if (activeChat._id === message.header._id) {
        setReceiveNewMessage(message.body)
      }
    }
  }

  const handleSendReadHeader = (userId, headerId) => {
    if (socketMessengerConnection) {
      console.log('HEADER READ ' + JSON.stringify(headerId))
      socketMessengerConnection.emit(CONSTANT.SOCKET_EMIT_READ_MESSAGE, {
        userId,
        headerId,
      })
    }
  }
  React.useEffect(() => {
    if (receiveMessage) {
      processMessageFromSocket(receiveMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveMessage])

  React.useEffect(() => {
    if (receiveHeaderMessage) {
      updateHeaderMessageFromSocket(receiveHeaderMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveHeaderMessage])

  const loadChatMessages = async () => {
    const response = await getChatHeaders()
    if (response.success && response.data) {
      console.log('LOAD MESSAGES BY HTTP')
      setHeaderMessages(response.data)
    }
  }

  const searchChatMessages = async (value) => {
    const response = await findChat(value)
    if (response.success && response.data) {
      console.log('LOAD MESSAGES BY HTTP IN SEARCH')
      setHeaderMessages(response.data)
    }
  }

  const handleSendNewMessage = (message) => {
    console.log('NEW MESSAGE RECEIVED')
    if (socketMessengerConnection) {
      console.log('NEW MESSAGE ' + JSON.stringify(message))
      socketMessengerConnection.emit(CONSTANT.SOCKET_EMIT_MESSAGE_EVENT, message)
    }
  }

  const handleSendNewFileMessage = (message) => {
    if (socketMessengerConnection) {
      socketMessengerConnection.emit(CONSTANT.SOCKET_SEND_FILE_EVENT, message)
    }
  }

  React.useEffect(() => {
    if (token) {
      if (!socketNotificationsConnection) {
        const socket = socketIOClient(config.SOCKET_URL + config.SOCKET_NOTIFICATIONS_NAMESPACE, {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 10000,
          jsonp: false,
          transports: ['websocket'],
          autoConnect: true,
          agent: '-',
          pfx: '-',
          rejectUnauthorized: '-',
          perMessageDeflate: '-',
          query: {
            userId: user.userId,
            token: token,
          },
        })

        setSocketNotificationsConnection(socket)

        socket.on(CONSTANT.SOCKET_TOTAL_PRODUCTS_ACTIVE_SHOPPING_CART, (response) => {
          Number(response) ? setQtyShoppingCart(response) : setQtyShoppingCart(0)
        })

        socket.on(CONSTANT.SOCKET_TOTAL_ACTIVE_ORDERS, (response) => {
          setQtyOrders(response)
        })

        socket.on(CONSTANT.SOCKET_TOTAL_MESSAGES, (response) => {
          setQtyMessage(response)
        })

        socket.on('connect_failed', function () {
          console.log(' socket Connection Failed')
        })

        const socketMessenger = socketIOClient(config.SOCKET_URL + config.SOCKET_MESSENGER_NAMESPACE, {
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 10000,
          jsonp: false,
          transports: ['websocket'],
          autoConnect: true,
          agent: '-',
          pfx: '-',
          rejectUnauthorized: '-',
          perMessageDeflate: '-',
          query: {
            userId: user.userId,
            token: token,
          },
        })
        setSocketMessengerConnection(socketMessenger)

        socketMessenger.on(CONSTANT.SOCKET_NEW_MESSAGES, (data) => {
          setReceiveMessage(data)
        })

        socketMessenger.on(CONSTANT.SOCKET_RECEIVE_FILE_EVENT, (data) => {
          setReceiveHeaderMessage(data?.header)
          setReceiveMessage(data)
        })

        socketMessenger.on(CONSTANT.SOCKET_UPDATE_HEADER_EVENT, (header) => {
          setReceiveHeaderMessage(header)
        })

        socketMessenger.on('connect_failed', function () {
          console.log(' socketMessenger Connection Failed')
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <Provider
      value={{
        qtyShoppingCart,
        qtyOrders,
        qtyMessage,
        handleDeleteShoppingCart,
        socketNotificationsConnection,
        setQtyOrders,
        loadChatMessages,
        searchChatMessages,
        headerMessages,
        activeChat,
        setActiveChat,
        bodyMessages,
        setBodyMessages,
        handleSendReadHeader,
        handleSendNewMessage,
        handleSendNewFileMessage,
        receiveNewMessage,
        disconnect,
      }}
    >
      {children}
    </Provider>
  )
}

export { SocketProvider, Consumer as CartConsumer, SocketContext }
