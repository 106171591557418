import React from 'react'

import styleCSS from './style'

import Backdrop from '@material-ui/core/Backdrop'

import CircularProgress from '@material-ui/core/CircularProgress'
import BrowserLanguage from '../../../utils/browserLanguage';
import logoEs from '../../../images/Logos/LogoHarfonEspanol.png'
import logoIn from '../../../images/Logos/LogoHarfonIngles.png'

export default function SpinnerLoader() {
  const classes = styleCSS()
  const language = BrowserLanguage.getDefaultLanguage(); 

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <img src={language === 'es' ? logoEs : logoIn} alt="Harfon-Logo" className={classes.logoSize} />
      <CircularProgress color="primary" />
    </Backdrop>
  )
}
