import React from 'react'
import { useTranslation } from 'react-i18next'

import styleCSS from './style'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CloseRounded from '@material-ui/icons/CloseRounded'

import Photo from '../../../images/voucher/photo.svg'
import arrowTop from '../../../images/voucher/upload.svg'
import { formatImgEnum } from '../../../utils/enums'

function CardUploadFile({
  onSelected = () => {},
  deleteFile = () => {},
  onMaxFileSizeError = () => {},
  accept = `image/*`,
  uploadAllow = true,
  files = [],
  maxFileSize = null,
  id = `buttonUpload`,
  disabled = false,
}) {
  const { t } = useTranslation(['my_account'])
  const classes = styleCSS()

  return (
    <>
      <Box className={classes.content_form}>
        {uploadAllow && (
          <Box className={classes.container_upload_file}>
            <label htmlFor={id} className={classes.content_upload_file}>
              <img src={Photo} alt="img-pho" />
              <button type="button" className={classes.button_upload}>
                <label htmlFor={id} className={classes.text_button_upload}>
                  {t('my_account:upload_file', 'Upload file')}
                  <img width="10px" alt="img-arrow-top" src={arrowTop} />
                </label>
              </button>
              <span className={classes.text_info}>
                {maxFileSize ? `${t('my_account:size_allow_file')} ${maxFileSize / 1024 / 1024}MB` : ``}
              </span>
              <input
                id={id}
                type={`file`}
                style={{ display: `none` }}
                onClick={(e) => (e.target.value = null)}
                accept={accept}
                onChange={(event) => {
                  const reader = new FileReader()
                  const file = event.target.files[0]
                  if (maxFileSize && file.size > maxFileSize) {
                    onMaxFileSizeError(true)
                    return
                  }
                  onMaxFileSizeError(false)
                  let name = ``
                  reader.addEventListener(`load`, () => onSelected(reader.result, name, file), false)
                  name = file.name
                  if (file) {
                    reader.readAsDataURL(file)
                  }
                }}
              />
            </label>
          </Box>
        )}
      </Box>
      <Box className={classes.row_file}>
        {files.map(({ file, fileName }, index) => {
          const fileNameObj = fileName.split('.')
          const isImage = formatImgEnum.includes(fileNameObj[fileNameObj.length - 1])

          return (
            <Box key={index} className={classes.content_file}>
              {!disabled && (
                <button type="button" onClick={() => deleteFile(index)} className={classes.btn_delete_file}>
                  <CloseRounded />
                </button>
              )}
              <Box
                className={classes.content_image}
                style={{
                  backgroundImage: `url(${isImage ? file : Photo})`,
                }}
              ></Box>
              <Typography noWrap>{fileName}</Typography>
            </Box>
          )
        })}
      </Box>
    </>
  )
}

export default CardUploadFile
