/** @format */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Redirect } from 'react-router-dom'

import FullPageLayout from '../fullPageLayout'

const FullPageLayoutPrivateRoute = ({ render, ...rest }) => {
  const { t } = useTranslation('routes')

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        localStorage.getItem('token') ? (
          <FullPageLayout>{render(matchProps)}</FullPageLayout>
        ) : (
          <Redirect
            to={{
              pathname: t('routes:login_route', '/login'),
            }}
          />
        )
      }
    />
  )
}

export default FullPageLayoutPrivateRoute
