import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

const styleCSS = makeStyles(({ spacing }) => ({
  btnMenuSideBarActive: {
    backgroundColor: `rgba(255, 255, 255, 0.15)`,
    color: `#FFF`,
    padding: spacing(2),
    borderRadius: spacing(1),
    cursor: `pointer`,
    marginBottom: spacing(1),
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    '&:hover': {
      backgroundColor: `rgba(255, 255, 255, 0.25)`,
    },
  },
  btnMenuSideBarDefault: {
    color: `#FFF`,
    padding: spacing(2),
    borderRadius: spacing(1),
    cursor: `pointer`,
    marginBottom: spacing(1),
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
    '&:hover': {
      backgroundColor: `rgba(255, 255, 255, 0.25)`,
    },
  },
}))

function ButtonStyled({ typeBtn = `default`, children, isActive, isLoading = false, ...props }) {
  const classes = styleCSS()

  if (typeBtn === `menuSideBar`) {
    return (
      <Typography {...props} className={isActive ? classes.btnMenuSideBarActive : classes.btnMenuSideBarDefault}>
        {isLoading ? <CircularProgress size={24} /> : children}
      </Typography>
    )
  }
  return <Button {...props}>{isLoading ? <CircularProgress size={24} /> : children}</Button>
}

export default ButtonStyled
