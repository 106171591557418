/** @format */

// imports React + Librerias;
import React from 'react'
import ReactDOM from 'react-dom'
/* import { Provider } from "react-redux"; */
import 'typeface-roboto'

// imports style;
import './index.css'

// imports material-ui;

// imports componets;

// imports images + logo + icons;

// imports config + API;
import Router from './routes/Router'
import * as serviceWorker from './serviceWorker'
/* import { store } from "./config/configureStore"; */
import './config/i18n'
// imports to implement google analytics
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";

ReactDOM.render(<Router />, document.getElementById('root'));

if(process.env.REACT_APP_IS_PROD === 'true'){
  //Initialize GA4
  ReactGA.initialize(process.env.REACT_APP_ID_GOOGLE_ANALYTICS);

  const SendAnalytics = ()=> {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
      });
    }

  reportWebVitals(SendAnalytics);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
