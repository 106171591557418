import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const BreadcrumbContext = React.createContext()

function BreadcrumbProvider({ children }) {
  const [breadcrumb, setBreadcrumb] = useState([])
  const { pathname, state } = useLocation()

  function updateBreadcrumb() {
    const fullName = `${pathname.split('/')[1]}`
    const name = fullName.split('-').join(' ')
    setBreadcrumb((prevState) => [
      ...prevState,
      { name: name.toLowerCase(), path: pathname, state: state ? state : {} },
    ])
  }

  useEffect(() => {
    window.scroll(0, 0)
    if (pathname === '/') {
      setBreadcrumb([])
    } else {
      updateBreadcrumb()
    }
  }, [pathname])

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumb,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  )
}

function useBreadcrumb() {
  return useContext(BreadcrumbContext)
}

export { BreadcrumbProvider, useBreadcrumb }
