import BrowserLanguage from '../utils/browserLanguage'

import { createTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale'

const language = BrowserLanguage.getDefaultLanguage()
const locale = language === 'en' ? 'enUS' : 'esES'

const palette = {
  primary: {
    main: '#BE1919',
    dark: '#991B1B',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#464647',
    dark: '#A6A6A6',
    light: '#F5F5F5',
    contrastText: '#FFF',
  },
  tertiary: {
    main: '#D9D9D9',
    dark: '#747E8A',
    contrastText: '#FFF',
  },
  textSecondary: {
    main: '#4B5563',

    contrastText: '#FFF',
  },
  blueMR: { main: '#F3F8FE', dark: '#D9D9D9', contrastText: '#4B5563' },
  greyPrimary: { main: '#D9D9D9', dark: '#B0B1B2', contrastText: '#4B5563' },
  secondaryMR: {
    main: '#991B1B',
    contrastText: '#FFF',
  },
  textMR: {
    main: '#071C2E',
  },
}

const typography = {
  h1: {
    fontSize: 32,
  },
  h2: {
    fontSize: 28,
  },
  h3: {
    fontSize: 24,
  },
  h4: {
    fontSize: 22,
  },
  h5: {
    fontSize: 20,
  },
  h6: {
    fontSize: 18,
    fontWeight: 300,
    color: '#000',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 400,
    color: '#000',
  },
  body1: {
    fontSize: 16,
  },
  body2: {
    fontSize: 14,
  },
  caption: {
    fontSize: 12,
  },
  overline: {
    fontSize: 12,
  },
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1520,
  },
}

const themeMDR = createTheme(
  {
    palette,
    typography,
    breakpoints,
  },
  locales[locale],
)

export default themeMDR
