import { makeStyles } from '@material-ui/core/styles'

const styleCSS = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'white',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  logoImage: {
    width: '15%',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.between(320, 700)]: {
      width: '55%',
    },
  },
  logoSize: {
    height: 130,
    width: 130,
    /*[theme.breakpoints.only('xs')]: {
      height: 80,
      width: 80,
    },
    [theme.breakpoints.between(600, 1210)]: {
      width: '50%',
    },
    [theme.breakpoints.between(960, 1045)]: {
      width: '50%',
    }, */
  },
}))

export default styleCSS
